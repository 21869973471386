<template>
  <div>
    <KTCodePreview v-bind:title="'Nhập sản phẩm'">
      <template v-slot:preview>
        <b-form ref="form" class="row">
          <!-- Input session -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col>
                <div class="form-group">
                  <span>
                    <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                    đúng format, hoặc có thể tải ở
                    <a :href="urlExcel">đây</a>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <b>Bước 2:</b>
                  <span>Vui lòng chọn "Loại" trước khi Import File Excel</span>
                  <b-form-select
                    class="select-style mt-3"
                    v-model="selectedType"
                    :options="listType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    @change="hideOrShowColumn($event)"
                  >
                    <template #first>
                      <option :value="null" disabled>
                        -- Vui lòng chọn Loại --
                      </option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span> <b>Bước 3:</b> Tiến hành Import </span>
                <div style="padding-top: 15px; display: flex">
                  <b-form-file
                    style="display: none"
                    :disabled="!selectedType"
                    placeholder="Hãy chọn một tập tin"
                    @change="selectFile"
                    v-model="file"
                    ref="file-input"
                    class="mb-2"
                    id="form-import"
                  ></b-form-file>
                  <b-form-input
                    type="text"
                    :disabled="!selectedType"
                    v-model="textFile"
                    placeholder="Hãy chọn một tập tin"
                    @click="onOpenFile('form-import')"
                    v-on:keydown.prevent
                    style="caret-color: transparent; cursor: pointer"
                    autocomplete="off"
                  ></b-form-input>
                  <b-button
                    variant="secondary"
                    :disabled="!file"
                    v-b-tooltip.hover
                    @click="uploadFile"
                    style="height: 39px; width: 4%"
                    title="Tải file"
                    ref="up_excel"
                  >
                    <i class="fas fa-upload text-primary"></i>
                  </b-button>
                  <b-button
                    style="height: 100%; width: 4%"
                    variant="secondary"
                    :disabled="!file"
                    @click="onClearFiles"
                    v-b-tooltip.hover
                    title="Xoá file"
                    ><i class="fas fa-trash text-danger"></i
                  ></b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <!-- End input session -->
          <!-- Show status -->
          <b-container class="mt-10">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="selectedStatus"
                @change="onChangeStatus()"
                :aria-describedby="ariaDescribedby"
                name="radios-btn-default"
                buttons
              >
                <b-form-radio value="all"
                  >Tất cả
                  <span class="text-primary"
                    >({{ countAll }})</span
                  ></b-form-radio
                >
                <b-form-radio value="valid"
                  >Hợp lệ
                  <span class="text-success"
                    >({{ countValid }})</span
                  ></b-form-radio
                >
                <b-form-radio value="error"
                  >Lỗi
                  <span class="text-danger"
                    >({{ countInValid }})</span
                  ></b-form-radio
                ></b-form-radio-group
              >
            </b-form-group>
            <b-table
              class="myTable"
              bordered
              hover
              :fields="visibleField"
              :items="importItems"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template v-slot:cell(listedPrice)="row">
                <p>{{ convertPrice(row.item.listedPrice) }}</p>
              </template>
              <template v-slot:cell(productCategory)="row">
                <p v-if="row.item.categoryName">
                  {{ row.item.categoryName }}
                </p>
              </template>
              <template v-slot:cell(productWebCategory)="row">
                <p v-if="row.item.internalCateName">
                  {{ row.item.internalCateName }}
                </p>
              </template>
              <template v-slot:cell(productType)="row">
                <p v-if="row.item.productType">
                  {{ row.item.productType.name }}
                </p>
              </template>
              <template v-slot:cell(brand)="row">
                <p v-if="row.item.brand">{{ row.item.brand.name }}</p>
              </template>
              <template v-slot:cell(isValid)="row">
                <span
                  v-text="getStatusName(row.item)"
                  class="label font-weight-bold label-lg label-inline"
                  v-bind:class="getStatusClass(row.item)"
                >
                </span>
              </template>
            </b-table>
            <b-pagination
              v-show="importItems.length >= 10"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table-product"
              align="right"
            ></b-pagination>
          </b-container>

          <b-container class="mt-10" v-show="importItems.length > 0">
            <h5>Tổng số sản phẩm từ file excel: {{ importItems.length }}</h5>
            <b-table
              v-if="false"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fields"
              :items="importItems"
            >
              <template v-slot:cell(originalPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.originalPrice)"></span>
                </div>
              </template>

              <template v-slot:cell(sellingPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.sellingPrice)"></span>
                </div>
              </template>

              <template v-slot:cell(weight)="row">
                <div style="text-align: end">
                  <span v-text="row.item.weight"></span>
                </div>
              </template>

              <template v-slot:cell(length)="row">
                <div style="text-align: end">
                  <span v-text="row.item.length"></span>
                </div>
              </template>

              <!-- <template v-slot:cell(length)="row">
                    <div style="text-align: end;">
                      <span v-text="row.item.length"></span>
                    </div>
                  </template>-->

              <template v-slot:cell(height)="row">
                <div style="text-align: end">
                  <span v-text="row.item.height"></span>
                </div>
              </template>
            </b-table>
          </b-container>
        </b-form>
      </template>
      <template v-slot:foot>
        <div class="bv-example-row container">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="importProduct"
              >Lưu</b-button
            >
            <router-link to="/products" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.table th,
.table td {
  text-align: center;
}

.myTable /deep/ .table {
  width: max-content !important;
  color: #464e5f;
  background-color: transparent;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
// import xlsx from 'xlsx';
import axios from 'axios';
import { URL_IMPORT_PRODUCT } from '@/utils/constants';
import { cloneDeep } from '@/utils/common';
import { ACTION_TYPE } from '@/utils/enum';
// const MAX_SIZE = 93184;

export default {
  data() {
    return {
      tmpImportItems: [],
      selectedStatus: 'all',
      currentPage: 1,
      perPage: 5,
      textFile: '',
      fields: [
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          visible: true,
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          visible: true,
        },
        {
          key: 'productCategory',
          label: 'Danh mục',
          thStyle: { width: '12%' },
          visible: true,
        },
        {
          key: 'productWebCategory',
          label: 'Danh mục nội bộ',
          thStyle: { width: '12%' },
          visible: true,
        },
        {
          key: 'listedPrice',
          label: 'Giá niêm yết',
          visible: true,
        },
        {
          key: 'productType',
          label: 'Loại sản phẩm',
          visible: true,
        },
        {
          key: 'brand',
          label: 'Thương hiệu',
          visible: true,
        },
        {
          key: 'isValid',
          label: 'Trạng thái',
          thStyle: { width: '10%' },
          visible: true,
        },
        {
          key: 'message',
          label: 'Thông báo',
          thStyle: { width: '10%' },
          visible: true,
        },
      ],
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      urlExcel: URL_IMPORT_PRODUCT,
      selectedType: null,
      listType: [
        {
          id: 1,
          name: 'Tạo mới',
        },
        {
          id: 2,
          name: 'Cập nhật',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Danh sách sản phẩm', route: '/products' },
      { title: 'Nhập sản phẩm' },
    ]);
  },
  computed: {
    countAll() {
      return this.tmpImportItems.length;
    },
    countValid() {
      return this.tmpImportItems.filter((item) => item.isValid).length;
    },
    countInValid() {
      return this.tmpImportItems.filter((item) => !item.isValid).length;
    },
    getStatusName() {
      return (objItem) => {
        if (!objItem.isValid) return 'Lỗi';
        return 'Hợp lệ';
      };
    },
    getStatusClass() {
      return (objItem) => {
        if (!objItem.isValid) return 'label-light-danger';
        return 'label-light-success';
      };
    },
    visibleField() {
      return this.fields.filter((field) => field.visible);
    },
    rows() {
      return this.importItems.length;
    },
  },
  methods: {
    onOpenFile(name) {
      document.getElementById(name).click();
    },
    uploadFile() {
      const submitButton = this.$refs['up_excel'];
      this.$refs['up_excel'].innerHTML = '';
      submitButton.classList.add(
        'spinner',
        'spinner-primary',
        'spinner-md',
        'spinner-center',
        'px-7',
      );
      this.onValidateDataExcel();
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onClearFiles() {
      this.file = null;
      this.importItems = [];
      this.tmpImportItems = [];
      this.textFile = '';
      this.$refs['file-input'].reset();
    },
    onChangeStatus() {
      switch (this.selectedStatus) {
        case 'all':
          this.importItems = cloneDeep(this.tmpImportItems);
          break;
        case 'valid':
          this.importItems = this.tmpImportItems.filter((item) => item.isValid);
          break;
        case 'error':
          this.importItems = this.tmpImportItems.filter(
            (item) => !item.isValid,
          );
          break;
      }
    },
    hideOrShowColumn() {
      // hide or show productType column base on selected type
      // if selected type = 2 (update) then hide the product type column
      if (this.selectedType === ACTION_TYPE.UPDATE) {
        for (const field of this.fields) {
          if (field.key === 'productType') {
            field.visible = false;
            return;
          }
        }
      } else {
        for (const field of this.fields) {
          if (field.key === 'productType') {
            field.visible = true;
            return;
          }
        }
      }
    },
    onValidateDataExcel() {
      this.uploading = true;
      if (!this.file) {
        return;
      }
      let endpoint = '';
      if (this.selectedType === ACTION_TYPE.CREATE) {
        endpoint = 'selected-create-excel';
      } else {
        endpoint = 'selected-update-excel';
      }
      const submitButton = this.$refs['up_excel'];
      let formData = new FormData();
      const file = this.file;
      formData.append('files', file);
      const config = {
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };
      axios
        .post(`product/${endpoint}`, formData, config)
        .then((response) => {
          this.$refs['up_excel'].innerHTML =
            '<i class="fas fa-upload text-primary"></i>';
          submitButton.classList.remove(
            'spinner',
            'spinner-primary',
            'spinner-md',
            'spinner-center',
            'px-7',
          );
          if (response.data.status === 0) {
            return this.makeToastFaile(response.data.message);
          } else {
            const { data } = response.data;
            this.importItems = data;
            this.tmpImportItems = data;
            this.makeToastSuccess(response.data.message);
          }
        })
        .catch((error) => {
          if (error) {
            this.$refs['up_excel'].innerHTML =
              '<i class="fas fa-upload text-primary"></i>';
            this.$nprogress.done();
            submitButton.classList.remove(
              'spinner',
              'spinner-primary',
              'spinner-md',
              'spinner-center',
              'px-7',
            );
            return this.makeToastFaile(error.message);
          }
        });
    },
    importProduct: async function () {
      this.uploading = true;
      if (!this.file || !this.importItems.length) {
        return;
      }
      const body = {
        listProduct: this.importItems,
        selectedType: this.selectedType,
      };

      const inValidItems = this.importItems.filter((item) => !item.isValid);
      if (inValidItems.length) {
        return;
      }
      axios
        .post('product/create-update-product-list', body)
        .then((response) => {
          console.log('responseConsoleLog', response);
          const { status, message } = response.data;
          if (status === 1) {
            this.clearFiles();
            this.$router.push({
              name: 'list-products',
              params: { importProductSuccess: true, message: message },
            });
            this.makeToastSuccess(message);
          }
          this.uploading = false;
        })
        .catch((e) => {
          console.log('Catch: ', e);
          this.makeToastFaile(e);
          this.uploading = false;
        });
    },
    selectFile(event) {
      const files = event.target.files[0];
      this.file = files;
      this.textFile = files.name;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return this.makeToastFaile(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>
